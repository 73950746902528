.header {
  &-logo {
    @apply flex flex-shrink-0 ml-[14px] lg:ml-[34px] w-[79px] h-[23px] lg:w-[158px] lg:h-[45px];

    figure {
      @apply grow;

      img {
        @apply w-full;
      }
    }
  }

  &-nav {
    @apply bg-white lg:border-b lg:border-neutral-50;

    &__mobile {
      @apply block lg:hidden;

      &-main {
        @apply flex justify-between items-center pl-[14px] pr-[20px] py-[12px] sticky-top bg-white;
      }

      &--main-button {
        @apply flex items-center gap-[6px] text-primary-0 text-xs;

        i {
          @apply flex items-center w-3 h-3;
        }
      }
    }

    .megamenu-mobile {
      @apply w-screen lg:hidden overflow-auto;  
      max-height: calc(100vh - 50px);

      &::before {
        content: "";
        @apply fixed inset-0 bg-neutral-bg -z-[1];
      }

      &__container {
        @apply px-[14px] pt-3 pb-[26px] bg-neutral-bg flex flex-col gap-[13px];
      }

      &--form {
        @apply w-full h-[52px] shrink-0 flex p-2 gap-2 bg-white rounded-md;

        i {
          @apply flex w-[42px] h-[42px] -mt-1 text-primary-0;
        }

        input {
          @apply w-full h-full outline-none px-1;
        }
      }

      &--primary {
        @apply bg-white rounded-xl px-[3px] py-3;

        .is-open {
          .is-collapsible {
            @apply text-primary-0;
          }

          .megamenu-mobile--primary-second {
            @apply mb-0;

            .is-collapsible.is-open {
              @apply px-0 py-0 gap-2 cursor-default;

              & > div {
                @apply flex-row-reverse justify-end gap-2 py-2 cursor-pointer;

                a {
                  @apply pointer-events-none;
                }

                i {
                  @apply transform rotate-180 pointer-events-none;
                }
              }
            }
          }
        }

        ul {
          @apply flex flex-col;

          .hidden,
          .child-hidden {
            display: none;
          }

          li {
            @apply flex flex-col gap-2;

            & > div {
              @apply text-xs sm:text-[14px] leading-[1.3] flex justify-between gap-4 text-neutral-100;

              a {
                @apply hover:underline hover:text-primary-0 grow;
              }

              i {
                @apply flex w-5 h-5 -mt-0.5 mr-[3px];
              }
            }
          }
        }

        & > ul {
          & > li {
            & > div {
              @apply py-[10px] rounded pl-[10px] pr-2;
            }

            & > ul {
              @apply bg-neutral-bg p-[3px] rounded-md gap-[3px] mb-2;

              & > li {
                @apply pl-[13px] pr-[7px] py-[9.5px] rounded;
              }
            }
          }
        }

        .megamenu-mobile--primary-third {
          @apply pl-8 gap-1;

          li {
            div {
              a {
                @apply w-full py-[10px] rounded pl-[10px] pr-2 hover:bg-[#FCF0F0];
              }
            }
          }
        }

        .is-collapsible {
          &:hover {
            @apply bg-[#FCF0F0];

            i {
              @apply text-primary-0;
            }
          }
        }

        &-first > .child-hidden + [data-collapsible] {
          @apply bg-white pb-0;

          li.is-open {
            @apply hover:bg-white;

            &:hover {
              i {
                @apply text-neutral-100;
              }
            }
          }
        }
      }

      &--secondary {
        ul {
          @apply flex flex-col gap-3 pl-[13px] mt-[6px] mb-2;

          li {
            @apply text-xs sm:text-[14px] leading-[1.3] text-neutral-100;

            a {
              @apply hover:underline hover:text-neutral-80;
            }
          }
        }
      }
    }

    &__top {
      @apply hidden lg:flex bg-primary-0 text-white py-1.5 min-h-[40px];

      & > .xl\:container {
        @apply px-[30px] w-full;

        @media screen and (min-width: 1366px) {
          max-width: 1366px !important;
        }
      }

      &--search {
        &-input {
          @apply relative  text-primary-0 overflow-hidden;

          & > span {
            @apply inset-y-0 left-0 flex items-center pl-1.5;
          }

          & > input {
            @apply w-[253px] h-7 py-1 text-sm rounded-[4px] pl-7 focus:outline-none text-neutral-100;
          }
        }

        &-dropdown {
          @apply absolute w-[253px] rounded-[8px] z-50 text-neutral-100 bg-neutral-0 mt-[15px] shadow-[0_0_8px_rgba(61,68,111,0.21)];

          & > div:first-child {
            @apply max-h-[208px];
          }
        }
      }
    }

    &__bottom {
      @apply flex flex-wrap lg:flex-nowrap gap-0.5 lg:gap-8 items-center justify-between p-0 lg:pl-[30px] lg:pr-4 lg:py-8;
    }

    &__primary-list {
      @apply hidden lg:flex;
      & > ul {
        @apply flex justify-center;
      }
    }

    &__item {
      &--link {
        position: relative;

        & > a {
          line-height: 1.4;
          text-align: center;
        }

        &-primary {
          & > a {
            @apply text-neutral-100 hover:text-opacity-60 px-[15px] flex;
          }

          &:nth-child(1n + 4) {
            &.hoverable {
              .mega-menu {
                @apply right-0;
              }
            }
          }

          &.hoverable {
            @apply relative hover:pt-[7px] hover:mt-[-7px] focus:pt-[7px] focus:mt-[-7px] hover:bg-neutral-10 focus:bg-neutral-10 rounded-t-[8px] hover:shadow-[0_1px_4px_rgba(0,0,0,0.25)];

            & > a {
              @apply relative block mb-[-1px] z-10 text-neutral-100;
            }

            &:hover > a,
            &:focus > a {
              @apply bg-neutral-10;
            }

            &:last-child {
              .dropdown-menu {
                @apply right-0;
              }
            }

            .mega-menu {
              @apply max-h-[65vh] overflow-x-auto hidden absolute mb-0 bg-neutral-10 text-neutral-80 w-[686px] rounded-b-[8px] shadow-[0_1px_4px_rgba(0,0,0,0.25)];

              &::-webkit-scrollbar {
                @apply w-[6px];
              }

              &::-webkit-scrollbar-thumb {
                @apply rounded bg-neutral-50;
              }

              &__content {
                @apply p-[20px];
              }

              &__table {
                tr {
                  th,
                  td {
                    @apply border-r border-neutral-40 last:border-r-0 last:w-[203px] first:w-[183px];
                  }
                }

                &--head {
                  tr th {
                    @apply px-[20px] pb-[6px] border-b border-neutral-40 align-baseline;

                    &:first-child {
                      @apply pl-0;
                    }

                    a {
                      @apply font-terpel-extrabold text-base leading-[22px] hover:text-neutral-100 hover:underline;
                    }
                  }
                }

                &--body {
                  tr td {
                    @apply align-baseline;

                    ul {
                      @apply px-[20px];

                      li {
                        a {
                          @apply hover:text-primary-0;
                        }
                      }
                    }

                    &:first-child ul {
                      @apply pl-0;
                    }
                  }
                }
              }
            }

            .dropdown-menu {
              @apply absolute hidden bg-neutral-10 w-[235px] shadow-[0_1px_4px_rgba(0,0,0,0.25)] z-0;

              &__list {
                @apply flex flex-col gap-4 w-full bg-neutral-10 px-8 py-6 rounded-b-[8px];

                li a {
                  @apply text-neutral-80 hover:text-primary-0;
                }
              }
            }

            &:hover,
            &:focus {
              a {
                @apply z-20;
              }

              .mega-menu,
              .dropdown-menu {
                @apply block z-10;
              }
            }
          }
        }

        &-secondary {
          @apply text-center;

          a {
            @apply hover:underline;
          }
        }
      }
    }
  }
}

.megamenu-is-open {
  @apply overflow-hidden lg:overflow-visible;
}

.header-primary{
  &-nav{
    &-logo{
      @apply relative;
      img {
        @apply block h-8 md:h-[2.8rem] w-auto transition-all duration-500;
      }
      i {
        @apply absolute inset-0 opacity-0;
      }
    }
    @apply uppercase;
    [name="page-builder-preview"] & {
      @apply pointer-events-none;
    }
    $self: &;
    &-wrapper {
      @apply max-w-[1500px] w-full mx-auto;
    }
    &__top {
      @apply px-3 py-3 xl:py-4.5 xl:px-4 bg-primary-0 hidden lg:block text-sm;
      #{$self}-wrapper{
        @apply flex justify-between gap-5;
      }
      &-menu {
        @apply text-[10px] xl:text-[12px] text-neutral-0 flex flex-wrap gap-y-5 -mx-4 items-start;
        #{$self}__item {
          @apply px-4;
        }
        #{$self}__item + #{$self}__item {
          @apply border-l border-current;
        }
      }
      &-search {
        @apply flex pr-4 items-center relative;
        input {
          @apply block outline-none w-0 py-1 focus:w-32 focus:mr-3 -my-1 rounded transition-all duration-500 text-blue-dark-10 indent-2;
        }

        &-icon {
          @apply cursor-pointer -my-1 flex items-center;
        }

        &-dropdown {
          @apply absolute right-4 w-[253px] rounded-[8px] z-50 text-neutral-100 bg-neutral-0 mt-[15px] shadow-[0_0_8px_rgba(61,68,111,0.21)];

          & > div:first-child {
            @apply max-h-[208px];
          }
        }
      }
    }
    &__bottom {
      @apply py-2.5 px-4 text-neutral-100 text-[12px] xl:text-[13px] bg-[#e7e8eb] transition-all duration-500;
      #{$self}-wrapper{
        @apply flex justify-between items-end gap-5;
      }
    }
    &__primary-list {
      @apply font-bold hidden lg:block;
      > ul {
        @apply flex gap-x-5 border-b border-current -mb-2.5 relative items-center;
        > li {
          @apply border-b border-transparent mb-[-1px] hover:border-primary-0 transition-all relative;
          &.pos-right {
            .dropdown-menu, .mega-menu {
              @apply right-0 left-[initial];
            }
          }
          > a {
            @apply pb-3 flex items-center gap-2 text-center;
            i {
              @apply w-4 aspect-square inline-block transition;
            }
          }
          &:not(.megamenu, .dropdown){
            i {
              @apply hidden;
            }
          }
          
          .dropdown-menu, .mega-menu {
            @apply absolute top-full left-0 mt-[1px] pointer-events-none opacity-0 transition;
          }
          .dropdown-menu {
            @apply flex;
          }
          .dropdown-menu__list, .mega-menu__list {
            @apply pt-5 bg-[#e7e8eb] text-blue-dark-10;
            box-shadow: inset 0 25px 20px -20px #0000004d;
            li {
              @apply whitespace-nowrap leading-normal transition;
              > {
                a, span {
                  @apply block px-6 py-3;
                }
              }
              
            }
            li:hover {
                @apply text-neutral-0 bg-blue-dark-10;
              }
          }
          &:hover {
            .dropdown-menu, .mega-menu {
              @apply pointer-events-auto opacity-100 ;
            }
            i {
              @apply text-primary-0 rotate-180;
            }
          }
        }
      }
    }
    &__mobile {
      @apply lg:hidden;
      i {
        @apply block w-6 aspect-square;
      }
    }
    .navbar-transparent &{
      #{$self}__bottom{
        @apply absolute top-full inset-x-0;
      }
    }
    .navbar-transparent.isTop & {
      #{$self}-logo {
        img {
          @apply opacity-0;
        }
        i {
          @apply opacity-100;
        }
      }
      #{$self}__bottom{
        @apply text-neutral-0 bg-transparent bg-gradient-to-b from-blue-dark-10/[0.2];
      }
    }
  }
}
.mega-menu {
  &__list {
    @apply relative;
  }
  &__item {
    @apply cursor-pointer z-10;
    $list: &;
    #{$list}__children {
      @apply text-neutral-0 pt-5 min-h-[100%] absolute top-0 left-full whitespace-nowrap bg-gradient-to-r from-blue-dark-10 via-blue-dark-10/[0.3] min-w-[50vw] opacity-0 pointer-events-none transition;
      .pos-right & {
        @apply left-[initial] right-full text-right bg-gradient-to-l;        
      }
    }
    &:hover{
      #{$list}__children {
        @apply pointer-events-auto opacity-100 ;
      }

    }
  }
}
.tools {
  @apply flex gap-6 items-center mb-9 justify-center;
  &-ubication{
    @apply flex gap-3 items-center;
    i {
      @apply -my-2 w-5 aspect-square;
    }
  }
  &-language{
    @apply whitespace-nowrap;
    word-spacing: 0.5em;
    .active {
      @apply opacity-60 pointer-events-none;
    }
  }
}
.megamenu-primary {
  &-mobile {
    @apply h-[calc(100vh-52px)] absolute top-full left-0 bg-neutral-10 w-full text-blue-dark-10 font-bold overflow-y-auto text-[13px] lg:hidden;
    $self: &;
    &--item {
      @apply flex gap-3;
      &-text {
        @apply block py-4 px-8 flex-grow;
      }
      .icon-arrow {
        @apply flex items-center w-12 pr-8;
        .arrow {
          @apply block w-full transition duration-500;
        }
      }
    }
    li{
      #{$self}--item {
        @apply border-t border-[#DADADA];
      }
    }
    &--primary {
      @apply border-b border-[#DADADA] mb-6;
      &-second {
        .is-open {
          #{$self}--item {
            @apply border-t border-[#DADADA];
            .icon-arrow{
              @apply rotate-180 text-primary-0 order-first -mr-8 pointer-events-none;
            }
          }
        }
      }
    }
    &--form {
      @apply relative mb-7 mx-8;
      input {
        @apply py-3 px-6 leading-none rounded-full w-full outline-none border border-[#DADADA]; 
      }
      i {
        @apply w-6 aspect-square absolute right-4 top-1/2 -translate-y-1/2;
      }
    }
    .child-hidden {
      display:none;
    }
    &--secondary{
      @apply px-5;
      ul:not(.social-media-primary) {
        @apply flex flex-wrap gap-y-5 justify-center;
        li {
          @apply px-5
        }
        li + li{
          @apply border-l border-current;
        }
      }
    }
    &--footer {
      @apply px-5 pt-6 pb-10 mt-11 border-t border-[#DADADA] flex flex-col items-center;
      .social-media-icon {
        @apply w-5
      }
    }
  }
}

.header-primary-nav{
  $self: &;
  &.unicos{
    #{$self}__bottom{
      @apply flex gap-5 items-center font-semibold;
      .header-unicos {
        @apply flex ml-auto gap-4 rounded-lg items-center whitespace-nowrap normal-case;
        .button {
          @apply py-2 px-6 leading-none rounded-xl;
        }
        .user-unicos {
          @apply flex gap-2 items-center;
          .user {
            @apply text-base;
          }
        }
      }
    }
  }
}