.layout {
  &--article,
  &--info-page,
  &--lubricant,
  &--landing-page {
    @apply relative xl:container mx-auto px-[14px] xs:px-8;

    [data-ibexa-zone-id] > [data-ez-block-id].is-last-block .force-full-width {
      @apply pb-[68px];

      &.promo-content__section--info-card.overlap-cards {
        &::before {
          height: calc(94px + 68px);
        }
      }
    }

    div[data-ibexa-zone-id] {
      @apply w-full;
    }

    .has-upper-block {
      @apply mt-4 sm:mt-8;
    }

    .has-bottom-block {
      @apply mb-4 sm:mb-8;
    }

    .has-background section {
      @apply py-[18px];
    }
  }

  &--article {
    &-content {
      @apply flex flex-col pb-[68px];
    }

    &__top {
      margin-left: -400px !important;
      margin-right: -400px !important;

      @media screen and (min-width: 1920px) {
        margin-left: calc(-50vw + 600px) !important;
        margin-right: calc(-50vw + 600px) !important;
      }

      @apply flex flex-col justify-center py-8 sm:px-8 md:px-16 lg:px-20 xl:px-[91px];

      &--image figure {
        @apply w-full mb-8;

        img {
          @apply w-full h-full aspect-[1120/407] object-cover object-center rounded-2xl;
        }
      }

      &--date {
        @apply text-sm;
      }

      & :is(h1, h2, h3, h4, h5, h6) {
        @apply text-[#183B56];
      }

      &--image + &--main-text {
        @apply mt-8;
      }

      &--main-text {
        @apply flex flex-col gap-[58px];

        h1 {
          @apply text-center text-[#333333];
        }
      }

      p {
        @apply text-[#5A7184];
      }

      .richtext--content {
        @apply pt-4;

        ol:not(.nav-tabs, .featured-list-content__list),
        ul:not(.nav-tabs, .featured-list-content__list) {
          @apply text-[#5A7184];
        }
      }

      &--image,
      &--main-text,
      .richtext--content {
        @apply px-[14px] xs:px-8 sm:px-14 md:px-24 lg:px-[123px] w-screen mx-auto xl:container;

        @media screen and (min-width: 1366px) {
          max-width: 1366px !important;
        }
      }
    }
  }

  &--info-page {
    @apply pb-14 md:pb-[68px];

    &__wrapper {
      @apply flex flex-col gap-5 lg:flex-row;
    }

    &__sidebar {
      @apply w-full lg:w-[280px] my-8;
      .sidebar {
        &_title {
          @apply text-lg mb-4;
        }
        &_wrapper {
          @apply p-5 rounded-xl bg-neutral-bg lg:sticky;
          .icon {
            @apply w-[1.15em] block;
          }
          ul {
            @apply flex flex-col gap-3;
          }
          a {
            @apply flex gap-2;
            span {
              @apply block w-1 flex-grow;
            }
          }
          a,
          a.active {
            @apply hover:text-primary-0;
          }
        }
      }
    }

    &__content {
      @apply flex flex-col flex-grow lg:w-1;
    }

    & :is(h1, h2, h3, h4, h5, h6, p) {
      @apply text-neutral-80;
    }

    &__main-text {
      @apply flex flex-col gap-8;

      h1 {
        @apply text-center text-[#183B56];
      }
    }

    &__main-text,
    .richtext--content {
      @apply sm:mx-8 md:mx-16 lg:mx-32 xl:max-w-[723px] xl:mx-auto my-8  self-stretch;

      ol:not(.nav-tabs, .featured-list-content__list),
      ul:not(.nav-tabs, .featured-list-content__list) {
        @apply text-neutral-80;
      }
    }

    .info-card-layout {
      @apply my-8 lg:mx-15 xl:mx-[186px];
    }
  }

  &--lubricant {
    @apply pb-14 md:pb-[68px];

    &__content {
      @apply flex flex-col sm:px-12 md:px-[90px] pt-[33px] gap-[58px];

      &--main {
        @apply grid grid-cols-1 2md:grid-cols-2 2xl:grid-cols-[476px_570px] gap-x-[75px] gap-y-[18px];

        .card-promo {
          @apply bg-neutral-10 p-[25px];

          &__a {
            @apply gap-[22px];
            figure {
              @apply bg-white rounded-xl;
            }
          }

          &__image {
            @apply aspect-[408/335] rounded-xl;
          }

          &__product {
            @apply flex flex-col gap-[74px] px-4;

            &--title {
              @apply text-2xl xs:text-3xl lg:text-[34px] font-terpel-extrabold;
            }

            &--links {
              @apply flex gap-8 text-primary-0;

              & > a {
                @apply underline;
              }
            }
          }
        }

        .body-promo {
          @apply text-left flex flex-col gap-[17px] pt-[19px];

          &__cta {
            @apply flex items-end mt-10;

            .button {
              @apply px-[28.5px] py-2;
            }
          }

          & ul:not(.nav-tabs) {
            @apply pl-5;

            li::marker {
              @apply text-primary-0;
            }
          }
        }
      }

      &--products {
        @apply flex flex-col gap-[34px] items-center;

        .grids {
          @apply grid grid-cols-1 xs:grid-cols-2 2md:grid-cols-3 2lg:grid-cols-4 gap-5;
        }

        .card-promo {
          @apply p-[14px];

          figure {
            @apply bg-neutral-0 rounded-[10px];
          }

          &__image {
            @apply aspect-[237/194] rounded-[10px];
          }

          &__content {
            @apply px-1.5;
          }
        }
      }
    }

    & :is(h1, h2, h3, h4, h5, h6) {
      @apply text-neutral-100;
    }
  }
}

.block-section {
  @apply py-6 md:py-14 px-4 flex-col overflow-x-hidden w-screen ml-[50%] relative left-[-50vw];
  &-wrapper {
    @apply max-w-5xl xl:max-w-7xl w-full mx-auto flex flex-col gap-7 md:gap-10;
  }
  &-full-width {
    @apply -mx-4 max-w-none w-auto;
  }
  &-title {
    @apply text-[25px] md:text-[35px] xl:text-[40px] font-bold md:col-span-2 text-neutral-100;
    &-wrapper {
      @apply grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8;
    }
    .view-more {
      @apply inline-flex gap-2 items-center text-xs md:text-sm font-semibold ml-2 hover:text-primary-0;
      font-family: "Nunito";
      i {
        @apply w-[0.9em] -my-1;
      }
    }
  }
}
