.infocard {
    @apply block px-[14px] py-6 sm:p-[29px] gap-1 shadow-[2px_2px_12px_1px_rgba(136,138,151,0.24)] rounded-2xl bg-white;

    &-content {
        @apply flex flex-col gap-2 h-full;

        &__title {
            @apply mt-1 text-[18px] leading-[25px] text-neutral-100 font-terpel-extrabold;
        }

        &__description {
            @apply leading-[25px] text-neutral-80;

            .ezrichtext-field {
                ul:not(.nav-tabs) {
                    @apply m-0 pl-6;
                }

                table {
                    tbody {
                        tr {
                            @apply grid grid-cols-1 xs:grid-cols-2 gap-2;

                            td {
                                &:nth-child(odd),
                                &:nth-child(even) {
                                    @apply bg-transparent;

                                    ul {
                                        @apply m-0 pl-6;

                                        li {
                                            @apply hyphens-auto;
                                        }
                                    }
                                }

                                @apply border-0;
                            }
                        }
                    }
                }
            }
        }

        &__icon {
            &--primary {
                @apply block w-[40px] h-[40px];
            }

            &--secondary {
                @apply block w-[16px] h-[16px] ml-1 shrink-0;
            }
        }

        &__link {
            @apply mt-[3px] flex justify-end grow items-end;

            & > a {
                @apply inline-flex items-center leading-[1.4rem] text-[16px] font-medium;
            }
        }

        &__cta {
            @apply hidden;
        }
    }
}

.grid-infocard {
    @apply grid grid-cols-1 gap-[20px] md:auto-rows-fr;

    &__columns-2 {
        @apply md:grid-cols-2;
    }

    &__columns-3 {
        @apply md:grid-cols-2 lg:grid-cols-3;
    }

    &__columns-4 {
        @apply md:grid-cols-3 lg:grid-cols-4;
    }

    &__columns-5 {
        @apply md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5;
    }

    &__columns-6 {
        @apply md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6;
    }

    &__columns-5,
    &__columns-6 {
        .infocard {
            @apply p-4;
        }
    }
}

.full-container--info-card {
    @apply z-10 relative;

    .promo-content {
        &__section-text {
            div > p {
                @apply font-nunito text-base leading-[1.4];
            }
        }

        &__cta {
            @apply hidden;
        }
    }

    &__footer-text {
        @apply text-center text-xl leading-[1.3] font-terpel-bold text-neutral-100;

        a {
            @apply text-primary-0 hover:underline;
        }
    }

    &__footer-text {
        &.text-align-left {
            @apply text-left;
        }
    }

    &__footer-text.text-align-left + &__cta {
        @apply justify-start;
    }

    &__cta {
        @apply flex justify-end -mt-6;
    }

    &.has-image {
        .grid-infocard {
            @apply -mt-[22px];
        }
    }

    &.is-left-title {
        @apply sm:flex-row sm:flex-wrap sm:gap-x-0;

        .promo-content {
            &__section-text,
            &__section-content {
                @apply sm:w-1/2;
            }

            &__section-text {
                @apply sm:pr-[20px] sm:text-left;
            }

            &__section-content {
                @apply sm:pl-[20px];

                .grid-infocard {
                    @apply grid grid-cols-1 gap-[20px];

                    &__columns-2,
                    &__columns-3,
                    &__columns-4,
                    &__columns-5,
                    &__columns-6 {
                        @apply md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2;
                    }
                }
            }

            &__section-text > * + .promo-content__cta {
                @apply md:mt-4;
            }

            &__cta {
                @apply flex mt-0 justify-center md:justify-start;
            }
        }

        .full-container--info-card {
            &__footer-text {
                @apply w-full;
            }
            &__cta {
                @apply hidden;
            }
        }
    }

    &.cta-is-float {
        @apply flex-row flex-wrap;

        .promo-content__section-text,
        .promo-content__section-content {
            @apply grow;
        }

        .full-container {
            &--info-card__footer-text {
                @apply w-3/4 grow text-left flex items-center;
            }
            &--info-card__cta {
                @apply m-0 justify-start items-center max-w-[200px] shrink-0;

                a {
                    @apply flex;
                }
            }
        }
    }
}

.promo-content__section--info-card {
    @apply relative;

    &.overlap-cards {
        &::before {
            content: "";
            @apply absolute bottom-0 w-full h-[94px] mx-auto inset-x-0 bg-white z-0;
        }
    }
}

.infocard-custom {
    @apply block mb-5 px-5 py-8 shadow-[2px_2px_12px_1px_rgba(136,138,151,0.24)] rounded-2xl bg-white;

    &__icon {
        @apply flex w-6 h-6 shrink-0;
    }

    &__title {
        @apply text-lg leading-[1.4] font-terpel-extrabold flex gap-1 text-neutral-90;
    }

    &__title + &__content {
        @apply mt-2 sm:mt-1.5;
    }

    &__content {
        ol,
        ul {
            margin-block-start: 0 !important;
            margin-block-end: 0 !important;
            padding-inline-start: 1.5rem !important;
        }

        td {
            @apply flex mb-1 relative sm:table-cell sm:mb-0 bg-transparent #{!important};
        }
    }
}

.info-card {
    @apply p-5 md:p-[30px] shadow-[2px_2px_12px_1px_rgba(136,138,151,0.24)] rounded-lg bg-white flex-grow;
    &-link {
        @apply flex flex-col;
    }
    &-content{
        @apply grid grid-cols-1 md:grid-cols-[auto_1fr] gap-3 md:gap-4 items-start; 
        &__icon {
            &--primary {
                @apply w-[20px] md:w-[30px]
            }
        }
        &__title {
            @apply text-[13px] md:text-[16px] xl:text-[20px] text-blue-dark-10;
        }
    }

}