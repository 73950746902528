.breadcrumb {
    @apply inline-flex xs:flex flex-wrap gap-2 items-center pt-8 lg:ml-7;

    & > li:first-child {
        @apply mb-1 xs:mb-0;
    }

    &__item {
        @apply flex gap-2 text-primary-0 font-semibold;

        &--active {
            @apply text-neutral-80 pointer-events-none cursor-default;
        }

        &::before {
            content: "/";
            @apply text-base text-inherit font-semibold text-primary-0;
        }
    }
}
