html {
  scroll-behavior: smooth;
}

.xl\:container {
  @media screen and (min-width: 1366px) {
    max-width: 1366px !important;
  }
}
sub, sup {
  font-size: 50%;
  position: relative;
  vertical-align: middle;
  line-height: 1;
}

i > svg,
span > svg {
  width: 100%;
  height: 100%;
}

.fill-none {
  fill: none;
}

.iframe--document-embed {
  @apply w-full h-full;
  @media screen and (max-width: 768px) {
    height: 450px !important;
  }
}

.c-block-preview {
  min-height: auto !important;
  overflow: inherit !important;

  .aos-init {
    transform: none !important;
    transition: none !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}

[v-cloak] {
  opacity: 1;
  visibility: hidden;
}
.fade-in-leave-active {
  position: absolute!important;
  top: 0;
}
.fade-in-enter-active, .fade-in-leave-active{
  transition: all 0.5s ease-out;
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

.flicking-pagination-bullets {
  @apply absolute inset-x-0 bottom-12 flex flex-wrap gap-x-2 gap-y-4 z-10 justify-center;
}

.flicking-pagination-bullet {
  @apply text-white border-b-2 border-current w-7 block cursor-pointer;
}

.flicking-pagination-bullet.flicking-pagination-bullet-active {
  @apply text-primary-0;
}

.social-media {
  &-primary {
    @apply  flex flex-wrap gap-6 justify-center md:justify-start;
  }
  &-icon {
    @apply flex items-center w-6 aspect-square;
  }
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: calc(2 * 1.4em)
}
.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-height: calc(3 * 1.4em)
}
.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  max-height: calc(4 * 1.4em)
}