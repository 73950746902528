.banner {
  @apply relative flex flex-col-reverse md:flex-row rounded-3xl overflow-hidden h-full 2xl:min-h-[343px] 2xl:max-h-[368px];

  & > &-x:first-child + &-middle {
    @apply hidden;
  }

  &-info {
    @apply w-full md:w-1/2 grow;
    &[class*="brand-"] {
      @apply pt-[30px] md:pt-0;
    }

    & + .banner-image {
      .banner-middle {
        @apply opacity-100 pointer-events-auto;
      }
    }
  }

  &-content {
    @apply flex flex-col justify-center gap-8 px-[14px] pt-5 pb-[15px] md:px-[91px] md:py-[45px] xl:py-5 h-full;

    &__title {
      @apply flex items-center gap-5 text-white;

      span {
        sup {
          @apply text-[40%] top-[-1em] text-inherit;
        }
        sub {
          @apply text-[40%] top-[0em] text-inherit;
        }
      }

      &--icon {
        @apply w-[69px] h-[69px] flex items-center text-current;
      }
    }

    &__description {
      @apply text-white;

      .ezrichtext-field {
        img {
          @apply mt-2 mb-7 max-w-full max-h-[70px] md:max-h-[90px] w-auto #{!important};
        }

        .align-left,
        .align-right,
        .align-center {
          &,
          img {
            @apply mb-2;
          }
        }
      }
    }

    &__cta {
      @apply w-full md:w-auto text-center cursor-pointer;
    }
  }

  &.has-image {
    &:not(.is_reverse) {
      .banner-content {
        @apply md:pr-16 xl:pr-[55px];
      }
    }

    &.is_reverse {
      .banner-content {
        @apply md:pl-[60px];
      }
    }
  }

  &.has-full-image {
    .banner-image {
      figure {
        @apply aspect-[1302/344];
      }
    }
  }

  &-image {
    @apply w-full md:w-1/2 md:grow relative;

    @media screen and (min-width: 1280px) {
      width: calc(50% - 20px);
    }

    figure {
      @apply w-full md:h-full aspect-[347/182] md:aspect-[651/343];

      img {
        @apply md:rounded-br-3xl md:rounded-tr-3xl object-cover object-center w-full h-full;
      }
    }
  }

  &-middle {
    @apply absolute w-14 h-14 md:w-[102px] md:h-[102px] opacity-0 pointer-events-none flex justify-center items-center rounded-full -translate-x-1/2 -translate-y-1/2 left-1/2 md:left-0 top-[90%];
    &[class*="brand-"] {
      @apply top-full w-[80px] h-[80px] md:w-[100px] md:h-[100px] md:top-1/2;
      .banner-middle__content {
        @apply w-4/5;
      }
      .banner-middle__content--icon {
        @apply w-full;
      }
    }
    @media screen and (min-width: 400px) {
      @apply top-[94%];
    }
    @media screen and (min-width: 460px) {
      @apply top-[94.5%];
    }
    @media screen and (min-width: 560px) {
      @apply top-[95%];
    }
    @media screen and (min-width: 630px) {
      @apply top-[96%];
    }
    @media screen and (min-width: 700px) {
      @apply top-[97%];
    }
    @media screen and (min-width: 768px) {
      @apply top-[50%];
    }

    &__content {
      &--icon {
        @apply w-[30px] h-[30px] md:w-14 md:h-14 flex items-center;
      }
    }
  }

  &.is_reverse {
    @apply md:flex-row-reverse;
  }
  &.is_rounded {
    .banner-content__title--icon {
      @apply pr-5 border-r border-current box-content;
    }
    &:not(.is_reverse){
      .banner-image {
        figure {
          img {
            @apply rounded-br-3xl  md:rounded-tl-3xl rounded-bl-[104px];
          }
        }
      }
    }
    &.is_reverse{
      .banner-image {
        figure {
          img {
            @apply rounded-bl-3xl  md:rounded-tr-3xl rounded-br-[104px];
          }
        }
      }
    }
  }

  .bg-transparent,
  .bg-white,
  .bg-neutral-0,
  .bg-neutral-5,
  .bg-neutral-10,
  .bg-yellow-dark-80,
  .bg-blue-light-80,
  .bg-blue-light-100 {
    .banner {
      &-content {
        &__title,
        &__description {
          @apply text-neutral-100;
        }
      }
    }
  }

  .bg-primary-light-80,
  .bg-primary-light-100,
  .bg-yellow-light-80 {
    .banner {
      &-content {
        &__title,
        &__description {
          @apply text-neutral-80;
        }
      }
    }
  }
}

.is_reverse {
  .banner {
    &-image img {
      @apply rounded-none;
    }
    &-middle {
      @apply md:left-full;
    }
  }
}

.banner-redesign {
  @apply text-neutral-0 flex flex-col lg:grid lg:grid-cols-2 items-center;
  $self: &;
  .bg-transparent,
  .bg-white,
  .bg-neutral-0,
  .bg-neutral-5,
  .bg-neutral-10,
  .bg-yellow-dark-80,
  .bg-blue-light-80,
  .bg-blue-light-100 {
    @apply text-neutral-80;
    #{$self}-content{
      &__title {
        @apply text-neutral-100;
      }

    }
  }

  .bg-primary-light-80,
  .bg-primary-light-100,
  .bg-yellow-light-80 {
    .banner {
      &-content {
        &__title {
          @apply text-neutral-100;
        }
        &__description {
          @apply text-neutral-80;
        }
      }
    }
  }
  &-info {
    @apply md:min-h-[300px] xl:min-h-[360px] max-w-[650px] lg:max-w-none p-5 pt-7 md:p-10 rounded-[10px] flex flex-col justify-center -mt-20 mx-5 md:-mt-40 md:mx-16 lg:mx-0 lg:my-0 relative z-10 order-2 lg:order-none;
    #{$self}.is_reverse & {
      @apply lg:order-2;
    }
    &.hasIcon {
      #{$self}.is_reverse & {
        @apply lg:pl-14;
      }
      #{$self}:not(.is_reverse) & {
        @apply lg:pr-14;
      }
    }
  }
  &-content {
    @apply flex flex-col gap-6;
    &__title {
      @apply flex gap-4 text-3xl-alt;
      &--icon {
        @apply w-16 aspect-square -my-4 inline-block;
      }
    }
    &__description {
      @apply text-[12px] md:text-[17px];
    }
    &__links {
      &--list {
        @apply flex flex-col gap-4;
      }
      &--item {
        @apply flex gap-3;
        i {
          @apply flex w-6 h-6 rounded-full bg-primary-0 text-white;
          svg {
            @apply w-4 m-auto;
          }
        }
        a {
          @apply py-0.5 px-6 leading-snug rounded-full text-[12px] md:text-base;
        }
      }
    }
    &__cta {
      @apply text-center w-full md:w-auto inline-block rounded-full py-2.5 px-5 leading-none;
    }
  }
  &-image {
    @apply relative w-full;
    figure {
      @apply aspect-[770/500];
      #{$self}.is_reverse & {
        @apply lg:mr-[-20%];
      }
      #{$self}:not(.is_reverse) & {
        @apply lg:ml-[-20%];
      }
    }
    img {
      @apply w-full h-full rounded-[10px] object-cover;
    }
  }
  &-middle {
    @apply flex absolute top-0 lg:top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-10 md:w-16 aspect-square rounded-full z-10;
    #{$self}.is_reverse & {
      @apply lg:left-0;
    }
    #{$self}:not(.is_reverse) & {
      @apply lg:left-full;
    }
    &__content {
      @apply w-3/4 m-auto;
    }
  }
  &.banner-video-tab {
    #{$self}-content {
      @apply flex flex-col gap-6;
      &__title {
        @apply flex gap-4 text-[20px] md:text-[26px];
      }
    }
    #{$self}-info {
      @apply m-0;
    }
    .banner-redesign-image {
      @apply relative aspect-[530/500];
      .video-icon {
        @apply flex absolute w-[18%] aspect-[100/80] rounded-xl bg-primary-0 mt-[11%] right-0;
        i {
          @apply m-auto w-[35%] text-white;
        }
      }
      figure {
        @apply absolute overflow-hidden rounded-lg ml-0 shadow-md;
        img {
          @apply absolute w-full h-full object-cover transition duration-500;
        }
        .img-tools {
          @apply absolute w-full bottom-0 left-0 p-[7%] flex gap-[7%] items-center;
          .icon {
            @apply w-[9%];
          }
          .progress {
            @apply w-[1px] rounded-full flex-grow py-[3%] relative overflow-hidden outline outline-1 outline-offset-[3px] outline-white;
            &::after {
              content: "";
              @apply absolute h-full w-[70%] top-0 transition-[width] duration-500;
              background: #fff;
            }
          }
        }
        &:nth-child(1) {
          @apply aspect-[380/420] w-[70%] ml-[20%];
        }
        &:nth-child(2) {
          @apply aspect-[270/230] w-[51%] mt-[11%];
        }
        &:nth-child(3) {
          @apply aspect-[270/180] w-[51%] right-0 bottom-0;
        }
      }
      &:hover {
        img {
          @apply scale-[1.2];
        }
        .img-tools {
          .progress {
            &::after {
              content: "";
              @apply w-full;
            }
          }
        }
      }
    }
  }
}

.banner-video {
  @apply relative aspect-[1300/840] z-10 flex flex-col;
  &-bg {
    @apply absolute w-full h-full -z-10 object-cover;
  }
  &-title {
    @apply text-[30px] md:text-[36px] lg:text-[42px] leading-snug font-terpel-extrabold;
  }
  &-description {
    @apply text-[16px] md:text-[18px] lg:text-[22px] leading-snug font-terpel-regular;
  }
  &-cta {
    @apply mr-auto text-base md:text-lg lg:text-xl rounded-md md:rounded-xl lg:rounded-2xl py-2 px-6 md:py-3 md:px-10 lg:py-4 lg:px-12 leading-tight bg-gradient-to-r from-[#2F30E9] to-[#14ADFF];
  }
  &-divider {
    @apply bg-gradient-to-r from-[#2F30E9] to-[#14ADFF] h-[2px];
  }
  &-info {
    @apply max-w-[370px] md:max-w-[440px] lg:max-w-[520px] text-white flex flex-col gap-5;
  }
  &-content {
    @apply flex flex-col justify-end flex-grow pt-40 p-5 pb-8 md:p-10 md:pb-20 lg:p-16 lg:pb-40 bg-gradient-to-t from-neutral-100/[0.8] via-[#26277F]/[0.5] to-[#26277F]/[0];
  }
}